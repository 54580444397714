<template>
  <div id="property">
    <div class="baner">
      <el-carousel
        :interval="4000"
        style="width: 100%"
        arrow="always"
        height="780px"
      >
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <el-image
            :class="className"
            fit="cover"
            style="width: 100%; height: 576px;"
            :src="
              bannerdd.length !== 0
                ? 'https://gxzw.linjiaxiaoda.com/picture/prod-api' + item.image
                : item.image
            "
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="box">
      <div class="box-li">
        <div class="tac fs36 fw6" style="margin-top: 112px">物业缴费</div>
        <div class="flex JCcenter box-li-tiao">
          <div class="box-li-tiao-L"></div>
          <div class="box-li-tiao-R"></div>
        </div>
        <div class="box-li-ol flex JCspaceBetween ALcenter">
          <div class="box-li-ol-bg1"></div>
          <div class="box-li-ol-box">
            <div class="box-li-ol-box-text fs16 hui333">
              全面提升物业工作效率，自动生成账单，业主通过微信公众号及小程序可自行查看到费用明细，线上支付随时随地便捷缴费，缴费后系统自动提送电子收寄及发票。数据投影，有据可查，更省心更放心。
            </div>
            <div class="box-li-ol-box-poa"></div>
          </div>
        </div>
      </div>
      <div class="box-li">
        <div class="tac fs36 fw6" style="margin-top: 112px">智慧安防</div>
        <div class="flex JCcenter box-li-tiao">
          <div class="box-li-tiao-L"></div>
          <div class="box-li-tiao-R"></div>
        </div>
        <div class="box-li-ol flex JCspaceBetween ALcenter">
          <div class="box-li-ol-bg2"></div>
          <div class="box-li-ol-box">
            <div class="box-li-ol-box-text fs16 hui333">
              全自研开发智慧小区平台，前端数字化采集，AI智能判别分析，异常事件自动报警推送，实现社区有效安全管控。
            </div>
            <div class="box-li-ol-box-poa"></div>
          </div>
        </div>
      </div>
      <div class="box-li">
        <div class="tac fs36 fw6" style="margin-top: 112px">数据总览</div>
        <div class="flex JCcenter box-li-tiao">
          <div class="box-li-tiao-L"></div>
          <div class="box-li-tiao-R"></div>
        </div>
        <div class="box-li-ol flex JCspaceBetween ALcenter">
          <div class="box-li-ol-bg3"></div>
          <div class="box-li-ol-box">
            <div class="box-li-ol-box-text fs16 hui333">
              通过多种经营业务管理系统，为业主、物业项目提供社会资源深挖掘实现社区资源合理利用及整合最大化。提升业主居住生活体验、物业高效人性服务质量，实现小区数字化升级。
            </div>
            <div class="box-li-ol-box-poa"></div>
          </div>
        </div>
      </div>
    </div>
    <Fuwu />
  </div>
</template>

<script>
import Fuwu from "@/components/Fuwu.vue";
import { getBanner } from "@/api/new";
export default {
  name: "Property",
  components: {
    // Tabbar,
    // Bottombox,
    Fuwu,
  },
  data() {
    return {
      bannerList: [{ id: 1, image: require("@/assets/wuyefuwu.png") }],
      bannerdd: [],
    };
  },
  mounted() {
    this.getbanner();
  },
  methods: {
    getbanner() {
      getBanner({ type: 3 }).then((res) => {
        console.log(res.data, "banner");
        this.bannerdd = res.data;
        if (res.data.length !== 0) {
          this.bannerList = res.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
#property {
  .baner {
    width: 1920px;
    height: 576px;
    // background-image: url(~@/assets/wuyefuwu.png);
    // background-size: 100% 100%;
  }

  .box {
    width: 1200px;
    margin: 0 360px;
    .box-li {
      width: 1200px;

      .box-li-tiao {
        width: 1200px;
        height: 6px;
        margin-top: 21px;
        .box-li-tiao-L {
          width: 64px;
          height: 6px;
          background: #f8951d;
          margin-right: 8px;
        }
        .box-li-tiao-R {
          width: 12px;
          height: 6px;
          background: #f8951d;
        }
      }

      .box-li-ol {
        height: 700px;

        .box-li-ol-bg1 {
          background-image: url(~@/assets/Property1.png);
          background-size: 100% 100%;
          width: 589px;
          height: 448px;
        }
        .box-li-ol-bg2 {
          background-image: url(~@/assets/Property2.png);
          background-size: 100% 100%;
          width: 589px;
          height: 387px;
        }
        .box-li-ol-bg3 {
          background-image: url(~@/assets/Property3.png);
          background-size: 100% 100%;
          width: 589px;
          height: 389px;
        }

        .box-li-ol-box {
          width: 470px;
          height: 300px;
          background: rgba(255, 255, 255, 0.8);
          box-shadow: 0px 0px 10px 4px rgba(202, 202, 202, 0.2);
          border-radius: 0px 0px 0px 0px;
          position: relative;

          .box-li-ol-box-text {
            width: 356px;
            margin: 50px 52px 0;
            line-height: 36px;
          }

          .box-li-ol-box-poa {
            position: absolute;
            right: 26px;
            bottom: 26px;
            width: 128px;
            height: 128px;
            z-index: 0;
            background-image: url(~@/assets/Propertyt.png);
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
</style>